import { type FunctionComponent } from 'react';
import cx from 'clsx';
import classes from './CognitoAuthenticator.module.css';
import { Authenticator, AuthenticatorProps } from '@aws-amplify/ui-react';
import { I18n } from 'aws-amplify/utils';

I18n.setLanguage('en');

I18n.putVocabularies({
  en: {
    'Sign in': 'Login',
    'Back to Sign In': 'Back to Login',
    'Signing in': 'Logging in',
  },
});

export const SHARED_FORM_FIELDS: AuthenticatorProps['formFields'] = {
  signIn: {
    username: {
      label: 'Email',
      type: 'email',
      placeholder: '',
    },
    password: {
      placeholder: '',
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: 'Confirmation code',
      placeholder: '',
    },
  },
  confirmSignUp: {
    confirmation_code: {
      label: 'Confirmation code',
      placeholder: '',
    },
  },
};

export interface CognitoAuthenticatorProps
  extends Omit<AuthenticatorProps, 'className'> {
  hideSignIn?: boolean;
}

const CognitoAuthenticator: FunctionComponent<CognitoAuthenticatorProps> = ({
  formFields,
  hideSignIn,
  ...restProps
}) => {
  return (
    <Authenticator
      className={cx(classes.root, { [classes.hideTabs]: hideSignIn })}
      formFields={{ ...SHARED_FORM_FIELDS, ...formFields }}
      {...restProps}
    />
  );
};

export default CognitoAuthenticator;
