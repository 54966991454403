import { useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import { PageWithLayout } from '@/types/AppWithLayout';
import CaireHead from '@/components/CaireHead';
import { useRouter } from 'next/router';
import { Alert, Button, Center, Loader, Stack } from '@mantine/core';
import { useLinkStaffAccountMutation } from '@/lib/graphql';
import { useCognitoSession, useCognitoAction } from '@/contexts/Cognito';
import CognitoAuthenticator from '@/components/CognitoAuthenticator';
import {
  confirmSignUp,
  ConfirmSignUpInput,
  signUp,
  SignUpInput,
} from 'aws-amplify/auth';
import { AuthenticatorProvider } from '@aws-amplify/ui-react-core';
import cx from 'clsx';
import classes from './index.module.css';
import { AuthenticatorProps } from '@aws-amplify/ui-react';

const FORM_FIELDS: AuthenticatorProps['formFields'] = {
  signUp: {
    username: {
      label: 'Email',
      placeholder: '',
      required: true,
      order: 1,
      type: 'email',
    },
    password: {
      placeholder: '',
      required: true,
    },
    confirm_password: {
      placeholder: '',
      required: true,
    },
  },
};

const LINK_STAFF_ID_ERROR =
  'User self-signed up, failed to find staff record in system. Need product owner to create staff record first';
const LINK_STAFF_ID_ERROR_USER_MSG =
  'Please contact your Caire administrator to have an account created for you.';

type SignInPhase = 'default' | 'didRegister' | 'linking';

// TODO: CRE-3108 Move login UI into separate phase routes within /auth/login|signup|initialize
const LoginWelcome: PageWithLayout = () => {
  const [error, setError] = useState<Error | null>();
  const [signInPhase, setSignInPhase] = useState<SignInPhase>('default');

  const { authUser, authenticated } = useCognitoSession();
  const { refetchUserMetadata, handleSignOut } = useCognitoAction();
  const { push, reload } = useRouter();

  const [linkAccountMutation] = useLinkStaffAccountMutation();

  const hasLinkedStaffId = !!authUser?.staffId;
  const shouldRedirect = hasLinkedStaffId;

  // Redirect user to the appropriate page after login, via the `auth/bypass`
  // director route.
  useEffect(() => {
    if (!shouldRedirect) {
      return;
    }
    push('/auth/bypass');
  }, [push, shouldRedirect]);

  // If the user is authenticated but has no staff ID, link the accounts
  useEffect(() => {
    if (!authenticated || hasLinkedStaffId) {
      return;
    }

    setSignInPhase('linking');

    (async () => {
      try {
        setError(null);
        await linkAccountMutation();
        // refetching user metadata will refresh the token, so
        // `authUser.staffId` should update.
        await refetchUserMetadata();
      } catch (e) {
        const error = e as Error;
        setError(
          error.message.includes(LINK_STAFF_ID_ERROR)
            ? new Error(LINK_STAFF_ID_ERROR_USER_MSG)
            : error
        );

        // If linking fails, we must sign out the user else they remain authenticated
        // with Cognito, and the login form will not be visible.
        await handleSignOut();
      }
    })();
  }, [
    authenticated,
    handleSignOut,
    hasLinkedStaffId,
    linkAccountMutation,
    refetchUserMetadata,
  ]);

  const handleSignUp = async (input: SignUpInput) => {
    const { username, password, options } = input;
    const user = await signUp({
      username,
      password,
      options: {
        autoSignIn: false, // can't auto-sign in when manual confirmation code is required
        userAttributes: {
          ...(options?.userAttributes ?? {}),
          email: username,
        },
      },
    });
    return user;
  };

  const handleConfirmSignUp = useCallback(
    async (input: ConfirmSignUpInput) => {
      const confirmed = await confirmSignUp(input);

      // Assume we are confirmed, but not yet logged in? Ergo, set state?
      setSignInPhase('didRegister');

      return confirmed;
    },
    [setSignInPhase]
  );

  if (error) {
    return (
      <>
        <CaireHead title="Login/Account Error" />
        <Alert
          variant="error"
          title={<h3>Could not finalize your account.</h3>}>
          <p>{error.message}</p>
        </Alert>
        <Button onClick={reload}>Reload</Button>
      </>
    );
  }

  if (signInPhase === 'linking') {
    return (
      <>
        <CaireHead title="Initializing account..." />
        <Loader size={48} color="white" />
        <p>Reticulating splines…</p>
      </>
    );
  }

  // The Authenticator SDK component has state quirks where it will simply disappear from
  // the render if props change dramatically. Therefore we make the context provider unique
  // based on the registration mode we want to display.
  if (signInPhase === 'didRegister') {
    return (
      <>
        <CaireHead title="Complete Registration - Login" />
        <AuthenticatorProvider key="auth.contextAfterRegistration">
          {/* If we registered, we now have to log in. Show the form without sign up option. */}
          <Alert variant="success">
            OK! You&apos;ve confirmed your email address. Now please log in to
            get started.
          </Alert>
          <CognitoAuthenticator
            key={'auth.afterSignUp'}
            initialState="signIn"
            hideSignUp
            formFields={FORM_FIELDS}
          />
        </AuthenticatorProvider>
      </>
    );
  }

  // By default, we allow users to tab across to create a Cognito account. The credentials
  // will need to match an existing staff user in the database in order to be linked.
  return (
    <>
      <CaireHead title="Login" />
      <AuthenticatorProvider key="auth.contextDefault">
        <CognitoAuthenticator
          key={'auth.regular'}
          services={{
            handleSignUp,
            handleConfirmSignUp,
          }}
          initialState="signIn"
          formFields={FORM_FIELDS}
        />
      </AuthenticatorProvider>
    </>
  );
};

LoginWelcome.getLayout = (page) => (
  <Center className={cx(classes.root)}>
    <Stack gap={64} align="center" w={'480'}>
      <header>
        <Image
          priority={true}
          src="/brand/caire-dark.svg"
          alt="Caire"
          width={250}
          height={70}
        />
      </header>
      {page}
    </Stack>
  </Center>
);

export default LoginWelcome;
